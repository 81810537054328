import { AtlasHtml, AtlasIcon } from "atlas-ds";
import classNames from "classnames";

export type AtlasInfoType = "info" | "success" | "warning" | "error";

export interface AtlasInfoProps {
  /**
   * Le titre de l'information
   */
  title: string;
  /**
   * Le contenu de l'information
   */
  children?: React.ReactNode;
  /**
   * Le type d'information
   */
  type?: AtlasInfoType;
  /**
   * Un lien à ajouter à la suite de l'information
   */
  link?: React.ReactNode;
  /**
   * Une icône à ajouter au titre (sinon, une icône par défaut sera utilisée)
   */
  icon?: string;
  /**
   * Rendre l'info plus discrète
   */
  mini?: boolean;
}

/**
 * Une information située dans le flux de la page, ne pouvant être fermée
 * (en opposition à un Toast, qui n'est pas attaché à une page et peut être
 * fermé)
 */
export function AtlasInfo(props: AtlasInfoProps) {
  return (
    <div
      className={classNames(
        "atlas-info",
        `u-atlas-color-${props.type || "info"}`,
        {
          "atlas-info--mini": props.mini,
        }
      )}
      role={
        props.type && ["warning", "error"].includes(props.type)
          ? "alert"
          : "status"
      }
    >
      <div className="atlas-info__header">
        <AtlasIcon name={props.icon ?? props.type ?? "info"} size="xs" />
        <p className="atlas-info__title">{props.title}</p>
      </div>
      {props.children && <AtlasHtml>{props.children}</AtlasHtml>}
      {props.link && <div className="atlas-info__link">{props.link}</div>}
    </div>
  );
}
