import { ForwardedRef, forwardRef } from "react";
import { AtlasFieldBaseProps, AtlasFieldInternal } from "../AtlasFieldInternal";

export interface AtlasFieldTextAreaInputProps {
  ref: ForwardedRef<any>;
  id: string;
  name: string;
  value?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  minLength?: number;
  maxLength?: number;
  rows?: number;
  "aria-invalid"?: "true" | "false";
  "aria-describedby"?: string;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
}

export interface AtlasFieldTextAreaProps
  extends AtlasFieldBaseProps<
    HTMLTextAreaElement,
    AtlasFieldTextAreaInputProps
  > {
  /**
   * La valeur du champ
   */
  value?: string;
  /**
   * Un placeholder
   */
  placeholder?: string;
  /**
   * Le nombre de caractères minimal pour le champ
   */
  minLength?: number;
  /**
   * Le nombre de caractères maximal pour le champ
   */
  maxLength?: number;
  /**
   * Le nombre de ligne (hauteur du textarea)
   */
  rows?: number;
}

/**
 * Une zone de texte multiligne
 */
export const AtlasFieldTextArea = forwardRef(function AtlasFieldTextArea(
  props: AtlasFieldTextAreaProps,
  forwardedRef: ForwardedRef<any>
) {
  const inputProps: AtlasFieldTextAreaInputProps = {
    ref: forwardedRef,
    id: props.name,
    name: props.name,
    value: props.value,
    placeholder: props.placeholder,
    required: props.required ? true : undefined,
    disabled: props.disabled ? true : undefined,
    minLength: props.minLength,
    maxLength: props.maxLength,
    rows: props.rows,
    "aria-invalid": props.error ? "true" : undefined,
    "aria-describedby": props.error ? `${props.name}-error` : undefined,
    onChange: props.onChange,
  };

  const input = props.inputConstructor ? (
    props.inputConstructor(inputProps)
  ) : (
    <textarea {...inputProps} />
  );

  return (
    <AtlasFieldInternal {...props}>
      <div className="atlas-fieldTextArea">{input}</div>
    </AtlasFieldInternal>
  );
});
