export interface CampusThemeProps {
  /**
   * Le contenu
   */
  children: React.ReactNode;
}

/**
 * Un conteneur permettant d'appliquer le thème Campus
 */
export function CampusTheme(props: CampusThemeProps) {
  return <div className="campus-theme">{props.children}</div>;
}
