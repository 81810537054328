import { AtlasIconProps } from "atlas-ds";
import classNames from "classnames";
import { FocusEventHandler, MouseEventHandler } from "react";

export interface AtlasIconButtonProps {
  /**
   * L'icône au sein du bouton
   */
  children: React.ReactElement<AtlasIconProps>;
  /**
   * Action exécutée au clic sur le bouton
   */
  onClick: MouseEventHandler<HTMLButtonElement>;
  /**
   * Le nom accessible du bouton, caché mais fourni aux technologies
   * d'assistance
   */
  ariaLabel: string;
  /**
   * Pour un bouton pouvant être actif ou inactif, l'état du bouton
   */
  ariaChecked?: boolean;
  /**
   * Un rôle ARIA
   * Le rôle "switch" représente un bouton pouvant être activé ou désactivé
   * Le rôle "switch" doit être accompagné de l'attribut `aria-checked`
   */
  role?: "switch";
  /**
   * Pour un bouton contrôlant l'ouverture/fermeture d'un élément, son état
   */
  ariaExpanded?: boolean;
  /**
   * L'ID de l'élément contrôlé par le bouton
   */
  ariaControls?: string;
  /**
   * Action exécutée au focus sur le bouton
   */
  onFocus?: FocusEventHandler<HTMLButtonElement>;
  /**
   * Désactiver le bouton
   */
  disabled?: boolean;
  /**
   * Ajouter un cercle autour du bouton
   */
  circled?: boolean;
}

/**
 * Une icône cliquable (incluse dans un bouton)
 */
export function AtlasIconButton(props: AtlasIconButtonProps) {
  return (
    <button
      type="button"
      className={classNames("atlas-iconButton", {
        "atlas-iconButton--circled": props.circled,
      })}
      aria-label={props.ariaLabel}
      aria-checked={props.ariaChecked}
      aria-expanded={props.ariaExpanded}
      aria-controls={props.ariaControls}
      role={props.role}
      onClick={props.onClick}
      onFocus={props.onFocus}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  );
}
