import { AtlasButton, AtlasLoading } from "atlas-ds";
import classNames from "classnames";
import { useId } from "react";

export interface AtlasValueProps {
  /**
   * Le label
   */
  label: string;
  /**
   * La valeur
   */
  children: React.ReactNode;
  /**
   * Placer le label et la valeur sur la même ligne, si possible.
   * Éviter d'utiliser uniquement pour gagner de la place.
   * Réserver pour pour des cas particuliers (comme une série de montants qui
   * seront ainsi alignés à droite).
   * Cette propriété est automatiquement active lorsqu'il y a des sous-valeurs
   * (children).
   */
  inline?: boolean;
  /**
   * Des sous-valeurs associées (par exemple le détail d'un total)
   * Utiliser AtlasValue en tant qu'enfant(s) de lui-même dans ce cas.
   */
  subvalues?:
    | React.ReactElement<AtlasValueProps>
    | React.ReactElement<AtlasValueProps>[];
  /**
   * La valeur sous forme de texte et/ou html, telle qu'elle pourra être copiée
   * dans le presse-papier grâce à un bouton apparaissant au survol
   */
  copyable?: {
    text?: string;
    html?: string;
  };
  /**
   * Une fonction à éxécuter après le copiage de la valeur
   */
  onCopy?: Function;
}

/**
 * Une valeur associée à un label
 */
export function AtlasValue(props: AtlasValueProps) {
  const onCopy = () => {
    navigator.clipboard
      .write([
        new ClipboardItem({
          ...(props.copyable?.text
            ? {
                ["text/plain"]: new Blob([props.copyable?.text], {
                  type: "text/plain",
                }),
              }
            : {}),
          ...(props.copyable?.html
            ? {
                ["text/html"]: new Blob([props.copyable?.html], {
                  type: "text/html",
                }),
              }
            : {}),
        }),
      ])
      .then(
        () => {
          if (props.onCopy) {
            props.onCopy();
          }
        },
        () => {
          console.error("Could not copy value:", props.copyable);
        }
      );
  };

  return (
    <div
      className={classNames("atlas-value", {
        "atlas-value--inline": props.inline || props.subvalues,
      })}
      tabIndex={props.copyable ? 0 : undefined}
    >
      <div className="atlas-value__inner">
        <div className="atlas-value__label">
          {props.label}

          {props.copyable && (
            <div className="atlas-value__copy">
              <AtlasButton
                icon="copy"
                level={4}
                onClick={onCopy}
                ariaLabel={`Copier ${props.label}`}
              >
                Copier
              </AtlasButton>
            </div>
          )}
        </div>
        <AtlasLoading.Loader />

        <div className="atlas-value__value">{props.children}</div>
      </div>

      {props.subvalues}
    </div>
  );
}

export interface AtlasValueUpdateProps {
  /**
   * La valeur précédente
   */
  before?: string;
  /**
   * La nouvelle valeur
   */
  children?: string;
  /**
   * Le texte à afficher en cas de champ vide
   */
  emptyValueLabel?: string;
}

/**
 * Une valeur mise à jour
 */
AtlasValue.Update = (props: AtlasValueUpdateProps) => {
  const oldId = useId();
  const newId = useId();

  return (
    <>
      <p id={oldId} aria-hidden="true" className="atlas-value__updateLabel">
        Ancienne valeur
      </p>
      <del
        aria-describedby={oldId}
        className={classNames({ "atlas-value__empty": !props.before })}
      >
        {props.before ?? props.emptyValueLabel ?? "Champ vide"}
      </del>
      <p id={newId} aria-hidden="true" className="atlas-value__updateLabel">
        Nouvelle valeur
      </p>
      <ins
        aria-describedby={newId}
        className={classNames({ "atlas-value__empty": !props.children })}
      >
        {props.children ?? props.emptyValueLabel ?? "Champ vide"}
      </ins>
    </>
  );
};
