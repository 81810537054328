interface AtlasHtmlProps {
  /**
   * Le contenu HTML
   */
  children: string | React.ReactNode;
}

/**
 * Le contenu de ce composant obtiendra un style générique pour du contenu
 * HTML (gras, italique, listes, niveaux de titre...)
 */
export function AtlasHtml(props: AtlasHtmlProps) {
  return typeof props.children === "string" ? (
    <div
      className="atlas-html"
      dangerouslySetInnerHTML={{ __html: props.children }}
    ></div>
  ) : (
    <div className="atlas-html">{props.children}</div>
  );
}
