import classNames from "classnames";
import React, { useState } from "react";

export interface AtlasRgpdProps {
  /**
   * Le label de l'information destinée aux technologies d'assistance.
   * Il s'agit d'une courte introduction au contenu réel.
   */
  ariaLabel: string;
  /**
   * Le contenu de l'information
   */
  children: React.ReactNode;
  /**
   * Action à éxécuter à la fermeture, en plus de la disparition elle-même
   */
  onClose?: () => void;
  /**
   * Un lien à afficher après le contenu
   */
  link?: {
    href: string;
    label: string;
    ariaLabel?: string;
    target?: string;
  };
}

/**
 * Information flottante relative au traitement des données.
 */
export function AtlasRgpd(props: AtlasRgpdProps) {
  const [show, setShow] = useState(true);
  const [closing, setClosing] = useState(false);

  const close = () => {
    if (props.onClose) {
      props.onClose();
    }

    setClosing(true);

    // Let the exit animation play, then remove
    setTimeout(() => setShow(false), 1000);
  };

  if (!show) {
    return <></>;
  }

  return (
    <aside
      className={classNames("atlas-rgpd", { "atlas-rgpd--closing": closing })}
      aria-label={props.ariaLabel}
    >
      <div className="atlas-rgpd__content">
        {props.children}

        <div className="atlas-rgpd__actions">
          {props.link && (
            <a
              href={props.link.href}
              target={props.link.target ?? undefined}
              aria-label={props.link.ariaLabel ?? undefined}
            >
              {props.link.label}
            </a>
          )}

          <button type="button" onClick={close}>
            Fermer
          </button>
        </div>
      </div>
    </aside>
  );
}
