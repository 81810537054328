import { AtlasIcon, AtlasLinkConstructor, AtlasLoading, defaultLinkConstructor } from 'atlas-ds';
import { useEffect, useRef, useState } from 'react';

export interface BreadcrumbPart {
  label: string
  href: string
}

export interface AtlasBreadcrumbProps {
  /**
   * La liste des liens parents de la page courante.
   */
  parts: BreadcrumbPart[]
  /**
   * Le titre de la page courante (dernière partie du fil d'ariane)
  */
  currentPageLabel: string;
  /**
   * Si un simple lien ne suffit pas, un constructeur de lien
  */
  linkConstructor?: AtlasLinkConstructor;
}

/**
 * Le fil d'ariane.
 * Une série de liens suivie du nom de la page actuellement visitée.
 * Sur les écrans de petite taille, le fil d'ariane n'est pas affiché par
 * défaut : il faut cliquer sur le bouton pour le dérouler.
 */
export function AtlasBreadcrumb(props: AtlasBreadcrumbProps) {
  const [open, setOpen] = useState(true);
  const detailsElement = useRef<HTMLDetailsElement>(null);

  // Use useEffect for client-side only execution
  useEffect(() => {
    // Warning : this value must be cohesive with CSS rules in Breadcrumb.scss
    if (!window.matchMedia('(min-width: 40em)').matches) {

      // Close the breadcrumb
      setOpen(false);
    }
  }, [])

  const onOpen = () => {
    setTimeout(() => detailsElement.current?.querySelector('a')?.focus());
  }

  const linkConstructor = props.linkConstructor ?? defaultLinkConstructor;

  const separator = <AtlasIcon name="arrow-right" size="xs" />;

  return <div className="atlas-breadcrumb">
    <AtlasLoading.Loader />
    <details ref={ detailsElement } open={ open }>
      <summary onClick={ onOpen }>Afficher le fil d'ariane</summary>
      <nav aria-label="Fil d'ariane">
        <ol>

          { props.parts.map((part, index) => {
            const isLastPart = index === (props.parts.length - 1);

            return <li
              key={ part.label }
              aria-current={ isLastPart ? 'page' : undefined}
            >
              { index > 0 && separator }

              { linkConstructor({href: part.href}, part.label, index) }
            </li>
          })}

          <li aria-current="page">
            { separator }
            <span>{ props.currentPageLabel }</span>
          </li>
        </ol>
      </nav>
    </details>
  </div>
};
