import classNames from "classnames";

export interface CampusPricesProps {
  /**
   * Le prix principal
   */
  mainPrice: string;
  /**
   * Les prix secondaires
   */
  additionalPrices?: {
    label: string;
    value: string;
    optional?: boolean;
  }[];
}

/**
 * Un détail de prix
 */
export function CampusPrices(props: CampusPricesProps) {
  return (
    <div className="campus-prices">
      <div className="campus-prices__main">{props.mainPrice}</div>

      {props.additionalPrices &&
        props.additionalPrices.map((price) => (
          <div
            className={classNames("campus-prices__additional", {
              "campus-prices__additional--optional": price.optional,
            })}
            key={price.label}
          >
            <div>{price.label}</div>
            <div>{price.value}</div>
          </div>
        ))}
    </div>
  );
}
