import { ForwardedRef, forwardRef } from "react";
import { AtlasFieldBaseProps, AtlasFieldInternal } from "../AtlasFieldInternal";

export interface AtlasFieldFileInputProps {
  ref: ForwardedRef<any>;
  id: string;
  name: string;
  type: "file";
  accept?: string;
  required?: boolean;
  disabled?: boolean;
  "aria-invalid"?: boolean;
  "aria-describedby"?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

export interface AtlasFieldFileProps
  extends AtlasFieldBaseProps<HTMLInputElement, AtlasFieldFileInputProps> {
  /**
   * Le nom du fichier sélectionné
   */
  selectedFileName?: string;
  /**
   * La liste des formats de fichier autorisés
   */
  accept?: string;
}

/**
 * Un champ d'upload de fichier
 */
export const AtlasFieldFile = forwardRef(function AtlasFieldFileProps(
  props: AtlasFieldFileProps,
  forwardedRef: ForwardedRef<any>
) {
  const inputProps: AtlasFieldFileInputProps = {
    ref: forwardedRef,
    id: props.name,
    name: props.name,
    type: "file",
    required: props.required ? true : undefined,
    disabled: props.disabled ? true : undefined,
    accept: props.accept,
    "aria-describedby": props.error ? `${props.name}-error` : undefined,
    "aria-invalid": props.error ? true : undefined,
    onChange: props.onChange,
  };

  const input = props.inputConstructor ? (
    props.inputConstructor(inputProps)
  ) : (
    <input {...inputProps} />
  );

  return (
    <AtlasFieldInternal {...props}>
      <div className="atlas-fieldFile">
        {input}
        {props.selectedFileName ?? (
          <p className="atlas-fieldFile__instructions">
            Glisser-déposer ou rechercher un fichier
          </p>
        )}
        <div className="atlas-fieldFile__cta">Choisir un fichier</div>
      </div>
    </AtlasFieldInternal>
  );
});
