import { AtlasLink, AtlasLinkProps, AtlasLinkConstructor } from 'atlas-ds'
import { default as NextLink } from "next/link"

export const linkConstructor: AtlasLinkConstructor = (props: any, content: React.ReactNode): JSX.Element => <NextLink {...props}>{content}</NextLink>

export default function Link(props: AtlasLinkProps) {
    return <AtlasLink
        {...props}
        linkConstructor={props.href.startsWith("/") ? linkConstructor : undefined}
    />
}
