export interface CampusBrancheCardProps {
  /**
   * Le nom de la branche
   */
  children: string;
}

/**
 * Une branche professionnelle
 */
export function CampusBrancheCard(props: CampusBrancheCardProps) {
  return <div className="campus-brancheCard">{props.children}</div>;
}
