import { AtlasLoading, AtlasValuesProps } from "atlas-ds";
import { MouseEventHandler, useEffect, useRef, useState } from "react";

/**
 * Une option sélectionnable
 */
export interface AtlasOptionsItem {
  /**
   * L'identifiant de l'option
   */
  id: string;
  /**
   * Le contenu de l'option.
   * Généralement une simple string.
   * Peut accueillir des contenus complexes (ex: AtlasValues).
   */
  content: React.ReactNode;
  /**
   * L'action à éxécuter à la sélection de cette option
   */
  onClick: MouseEventHandler<HTMLButtonElement>;
}

/**
 * Un groupe d'options
 */
export interface AtlasOptionsGroup {
  /**
   * L'identifiant du groupe
   */
  id: string;
  /**
   * Le nom affiché du groupe
   */
  label: string;
  /**
   * Les options contenues dans le groupe
   */
  options: AtlasOptionsItem[];
}

export interface AtlasOptionsProps {
  /**
   * Les options.
   * Soit un tableau d'options, soit un tableau de groupe d'options.
   */
  options: AtlasOptionsItem[] | AtlasOptionsGroup[];
  /**
   * Un squelette de chargement à afficher (c'est à dire une entité sans valeur)
   */
  loader?: React.ReactElement<AtlasValuesProps>;
}

/**
 * Une liste d'options sélectionnables
 */
export function AtlasOptions(props: AtlasOptionsProps) {
  const [search, setSearch] = useState("");

  const ref = useRef<HTMLUListElement>(null);
  const buttonsRef = useRef<HTMLButtonElement[]>([]);

  useEffect(() => {
    document.addEventListener("keydown", onKeydown);

    return () => {
      document.removeEventListener("keydown", onKeydown);
    };
  });

  const onKeydown = (event: KeyboardEvent) => {
    const isVisible =
      ref.current &&
      ref.current.checkVisibility &&
      ref.current.checkVisibility();

    const isValidSearch =
      event.key.length === 1 && event.key.match(/[a-zA-Z0-9]/);

    if (
      isVisible &&
      isValidSearch &&
      document.activeElement?.nodeName !== "INPUT"
    ) {
      buttonsRef.current.some((button) => {
        if (button?.textContent?.toLowerCase().startsWith(search + event.key)) {
          setSearch(search + event.key);
          button.focus();
          return true;
        }
      });
    }
  };

  useEffect(() => {
    const searchTimeout = setTimeout(() => setSearch(""), 1000);
    return () => clearTimeout(searchTimeout);
  }, [search]);

  const loaders = props.loader ? (
    <AtlasLoading.Loaders>
      {[1, 2, 3].map((index) => (
        <div key={`loader-${index}`} className="atlas-options__optionWrapper">
          <div className="atlas-options__option">{props.loader}</div>
        </div>
      ))}
    </AtlasLoading.Loaders>
  ) : (
    <></>
  );

  const getOption = (option: AtlasOptionsItem, index: number): JSX.Element => {
    return (
      <li key={option.id} className="atlas-options__optionWrapper">
        <button
          ref={(button) => {
            if (button) {
              buttonsRef.current[index] = button;
            }
          }}
          className="atlas-options__option"
          type="button"
          onClick={(option as AtlasOptionsItem).onClick}
        >
          {(option as AtlasOptionsItem).content}
        </button>
      </li>
    );
  };

  if (!props.options.length) {
    return <div className="atlas-options">{loaders}</div>;
  } else if ("content" in props.options[0]) {
    return (
      <ul ref={ref} className="atlas-options">
        {props.options.map((option, index) =>
          getOption(option as AtlasOptionsItem, index)
        )}
      </ul>
    );
  } else {
    let index = 0;

    return (
      <div className="atlas-options">
        {loaders}

        <ul ref={ref}>
          {props.options.map((group) => (
            <li key={group.id}>
              <ul>
                <li id={group.id} className="atlas-options__groupLabel">
                  {(group as AtlasOptionsGroup).label}
                </li>

                {(group as AtlasOptionsGroup).options.map((option) => {
                  return getOption(option as AtlasOptionsItem, ++index);
                })}
              </ul>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}
