import {
  AtlasCheckbox,
  AtlasCheckboxInputProps,
  AtlasCheckboxProps,
  AtlasFieldBaseProps,
  AtlasFieldInternal,
} from "atlas-ds";

export interface AtlasFieldCheckboxGroupProps
  extends AtlasFieldBaseProps<HTMLInputElement, AtlasCheckboxInputProps> {
  /**
   * Les options disponibles
   */
  options: Omit<AtlasCheckboxProps, "name" | "onChange">[];
}

/**
 * Une série de cases à cocher. Elles ne sont pas interactives dans la vue
 * Storybook : leur état doit être changé par le contexte appelant, en fonction
 * des événements déclenchés.
 */
export function AtlasFieldCheckboxGroup(props: AtlasFieldCheckboxGroupProps) {
  return (
    <fieldset
      id={props.name}
      className="atlas-fieldCheckboxGroup"
      aria-invalid={props.error ? "true" : undefined}
      aria-describedby={props.error ? `${props.name}-error` : undefined}
      tabIndex={-1}
      disabled={props.disabled}
    >
      <legend>
        {props.label} {props.instructions}
      </legend>
      <AtlasFieldInternal {...props} isFieldset>
        <div className="atlas-fieldCheckboxGroup__options">
          {props.options.map((option) => (
            <div
              className="atlas-fieldCheckboxGroup__option"
              key={`${props.name}-${option.value}`}
            >
              <AtlasCheckbox
                onChange={props.onChange}
                {...option}
                name={props.name}
                inputConstructor={props.inputConstructor}
              />
            </div>
          ))}
        </div>
      </AtlasFieldInternal>
    </fieldset>
  );
}
