
export interface AtlasSkipProps {
  /**
   * L'ID du conteneur principal (soit en principe de la balise `main`) vers
   * lequel le raccourci doit pointer.
   */
  mainElementId: string
}

/**
 * Lien d'accessibilité focusable au clavier et permettant d'accéder
 * directement au contenu principal. Ce composant doit être le premier élément
 * focusable de l'élément `body`. Il est invisible lorsqu'il n'est pas ciblé.
 */
export function AtlasSkip(props: AtlasSkipProps) {
  return <a className="atlas-skip" href={`#${props.mainElementId}`}>
    Aller au contenu principal
  </a>
};
