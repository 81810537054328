import classNames from "classnames";
import { ChangeEventHandler } from "react";
import { AtlasIcon } from "../../image";

export interface AtlasSelectInputProps {
  id: string;
  name: string;
  "aria-label"?: string;
  value?: string;
  onChange?: React.ChangeEventHandler<HTMLSelectElement>;
  disabled?: boolean;
  required?: boolean;
  "aria-invalid"?: boolean;
  "aria-describedby"?: string;
  children: JSX.Element;
}

export interface AtlasSelectOption {
  /**
   * La valeur de l'option
   */
  value: string;
  /**
   * Le label affiché
   */
  label: string;
}

export interface AtlasSelectProps {
  /**
   * Le nom technique du select
   */
  name: string;
  /**
   * Les options sélectionnables
   */
  options: AtlasSelectOption[];
  /**
   * Un label plus précis pour les technologies d'assistance.
   * À utiliser si AtlasSelect n'est pas utilisé via AtlasFieldSelect.
   */
  ariaLabel?: string;
  /**
   * La valeur de l'option sélectionnée
   */
  value?: string;
  /**
   * Ajouter une option initiale sans valeur
   */
  showEmptyOption?: boolean;
  /**
   * Le label de l'option initiale
   */
  emptyOptionLabel?: string;
  /**
   * La sélection d'une valeur est-elle obligatoire ?
   */
  required?: boolean;
  /**
   * Le select est-il désactivé ?
   */
  disabled?: boolean;
  /**
   * Afficher l'élément en taille réduite
   */
  mini?: boolean;
  /**
   * Le select est-il dans un état invalide ?
   */
  ariaInvalid?: boolean;
  /**
   * L'identifiant de l'élément décrivant l'erreur associée au select
   */
  ariaDescribedBy?: string;
  /**
   * Action à éxécuter lorsque le select change d'état
   */
  onChange?: ChangeEventHandler<HTMLSelectElement>;
  /**
   * Si un select natif ne convient pas, une fonction permettant d'en
   * construire un.
   */
  inputConstructor?: (props: AtlasSelectInputProps) => JSX.Element;
}

/**
 * Un sélecteur d'option. Son usage direct est à distinguer de l'usage au sein
 * d'un formulaire, pour lequel `AtlasFieldSelect` sera généralement plus
 * approprié.
 */
export function AtlasSelect(props: AtlasSelectProps) {
  const emptyOptionLabel = props.emptyOptionLabel ?? "Sélectionner";

  const inputProps: AtlasSelectInputProps = {
    id: props.name,
    name: props.name,
    "aria-label": props.ariaLabel,
    value: props.value,
    required: props.required ? true : undefined,
    disabled: props.disabled ? true : undefined,
    "aria-invalid": props.ariaInvalid,
    "aria-describedby": props.ariaDescribedBy,
    onChange: props.onChange,
    children: (
      <>
        {props.showEmptyOption && (
          <option value="">{props.disabled ? "" : emptyOptionLabel}</option>
        )}
        {props.options?.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </>
    ),
  };

  const select = props.inputConstructor ? (
    props.inputConstructor(inputProps)
  ) : (
    <select {...inputProps} />
  );

  return (
    <div
      className={classNames("atlas-select", {
        "atlas-select--mini": props.mini,
      })}
    >
      {select}

      <AtlasIcon name="arrow-down" size="xs" />
    </div>
  );
}
