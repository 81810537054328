import { AtlasDetails, AtlasIcon } from 'atlas-ds';
import { useRef } from "react";

/**
 * Une option de tri
 */
export interface CampusSortOption {
  /**
   * Le label
   */
  label: string;
  /**
   * L'icône représentant l'option
   */
  icon: string;
}

export interface CampusSortProps {
  /**
   * Le label de l'option de tri actuellement active
   */
  currentOption: CampusSortOption;
  /**
   * Les options de tri
   */
  options: CampusSortOption[];
  /**
   * L'action à éxécuter au click sur une option
   */
  onClick: (option: CampusSortOption) => void;
}

/**
 * Une liste d'options de tri
 */
export function CampusSort(props: CampusSortProps) {
  const detailsRef = useRef<HTMLDetailsElement>(null);

  const onClick = (option: CampusSortOption) => {
    close();
    props.onClick(option);
  };

  const close = () => {
    if (detailsRef.current) {
      detailsRef.current.open = false;
    }
  };

  return (
    <AtlasDetails
      className="campus-sort"
      ref={detailsRef}
    >
      <summary className="campus-sort__cta">
        <span>
          <span>
            <strong>Trier par : </strong>
            {props.currentOption.label}
          </span>
          <AtlasIcon size="xs" name="arrow-right" />
        </span>
      </summary>

      <ul className="campus-sort__options">
        {props.options?.map((option) => (
          <li key={option.label}>
            <button type="button" onClick={() => onClick(option)}>
              <AtlasIcon size="s" name={option.icon} />
              {option.label}
            </button>
          </li>
        ))}
      </ul>
    </AtlasDetails>
  );
}
