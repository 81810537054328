import classNames from "classnames";
import {
  ForwardedRef,
  ReactEventHandler,
  forwardRef,
  useEffect,
  useRef,
} from "react";

export interface AtlasDetailsProps {
  /**
   * Le contenu de l'élément
   */
  children: React.ReactNode;
  /**
   * La classe à associer à l'élément
   */
  className?: string;
  /**
   * Une ref React pour accéder à l'élément
   */
  ref?: React.RefObject<any>;
  /**
   * Une action à éxecuter lorsque l'élément est ouvert ou fermé
   */
  onToggle?: ReactEventHandler<HTMLElement>;
}

/**
 * Un élément `<details>` qui se fermera automatiquement lors d'un clic en
 * dehors de l'élément
 */
export const AtlasDetails = forwardRef(function AtlasDetails(
  props: AtlasDetailsProps,
  forwardedRef: ForwardedRef<any>
) {
  const fallbackRef = useRef<any>(null);
  const ref = (forwardedRef || fallbackRef) as React.RefObject<any>;

  useEffect(() => {
    document.addEventListener("click", onDocumentClickOrFocus);
    document.addEventListener("focusin", onDocumentClickOrFocus);
    document.addEventListener("keydown", onDocumentKeydown);

    return () => {
      document.removeEventListener("click", onDocumentClickOrFocus);
      document.removeEventListener("focusin", onDocumentClickOrFocus);
      document.removeEventListener("keydown", onDocumentKeydown);
    };
  });

  const onDocumentClickOrFocus = (event: MouseEvent | FocusEvent) => {
    if (
      ref.current &&
      ref.current.open &&
      !ref.current.contains(event.target)
    ) {
      close();
    }
  };

  const onDocumentKeydown = (event: KeyboardEvent) => {
    if (ref.current && ref.current.open && event.key === "Escape") {
      close();
    }
  };

  const close = () => {
    ref.current.open = false;
  };

  return (
    <details
      ref={ref}
      className={classNames("atlas-details", props.className)}
      onToggle={props.onToggle}
    >
      {props.children}
    </details>
  );
});
