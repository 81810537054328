export interface AtlasGridProps {
  /**
   * Les cellules de la grille
   */
  children: React.ReactNode;
  /**
   * Le nombre de colonnes. Automatiquement ramené à une colonne en cas d'espace
   * limité.
   */
  columnsCount: number;
  /**
   * L'espace à insérer entre chaque cellule
   */
  gap?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
}

/**
 * Un composant de présentation sous forme de grille.
 */
export function AtlasGrid(props: AtlasGridProps) {
  const totalGaps = props.gap
    ? `calc(var(--atlas-space-${props.gap}) * ${props.columnsCount - 1})`
    : "0rem";

  return (
    <div
      className="atlas-grid"
      style={{
        gridTemplateColumns: `repeat(auto-fill, minmax(max(calc(${
          100 / props.columnsCount
        }% - ${totalGaps}), var(--atlas-grid-col-min-width)), 1fr))`,
        ...(props.gap
          ? {
              gap: `var(--atlas-space-${props.gap})`,
            }
          : {}),
      }}
    >
      {props.children}
    </div>
  );
}

export interface AtlasGridCellProps {
  /**
   * Le nombre de colonnes sur lequel doit s'étendre la cellule, si possible
   */
  columnSpan?: number;
  /**
   * Le contenu de la cellule
   */
  children: React.ReactNode;
}

/**
 * Une cellule de la grille.
 * Ce composant est à utiliser dans les cas particuliers où il est nécessaire de
 * modifier le comportement par défaut de la cellule concernée.
 */
AtlasGrid.Cell = (props: AtlasGridCellProps) => {
  return (
    <div
      className="atlas-grid__cell"
      style={{
        gridColumn: props.columnSpan
          ? `auto / span ${props.columnSpan}`
          : undefined,
      }}
    >
      {props.children}
    </div>
  );
};
