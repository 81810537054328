import { AtlasIcon } from "atlas-ds";

export interface CampusDomaineCardProps {
  /**
   * Le nom du domaine
   */
  children: string;
}

/**
 * Un domaine de compétence
 */
export function CampusDomaineCard(props: CampusDomaineCardProps) {
  return (
    <div className="campus-domaineCard">
      <div className="campus-domaineCard__icon">
        <AtlasIcon name="domaine" />
      </div>
      {props.children}
    </div>
  );
}
