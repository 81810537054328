import {
  AtlasIcon,
  AtlasLinkConstructor,
  AtlasSearchDropdownProps,
  defaultLinkConstructor,
} from "atlas-ds";

export interface CampusCompanySearchProps {
  /**
   * Le composant de recherche
   */
  searchDropdown: React.ReactElement<AtlasSearchDropdownProps>;
  /**
   * Le lien vers le catalogue
   */
  catalogueHref: string;
  /**
   * Un constructeur de lien
   */
  linkConstructor?: AtlasLinkConstructor;
}

/**
 * L'interface de recherche d'un entreprise
 */
export function CampusCompanySearch(props: CampusCompanySearchProps) {
  const linkConstructor = props.linkConstructor || defaultLinkConstructor;

  return (
    <div className="campus-companySearch" role="search">
      <p className="campus-companySearch__note">
        Afin d'avoir un catalogue personnalisé, merci d'indiquer l'entreprise
        pour laquelle vous travaillez.
      </p>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        {props.searchDropdown}
      </form>
      {linkConstructor(
        { href: props.catalogueHref, className: "campus-companySearch__cta" },
        <>
          <AtlasIcon size="s" name="catalogue" />
          Voir les différentes branches
        </>
      )}
    </div>
  );
}
