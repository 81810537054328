import {
  AtlasCheckbox,
  AtlasCheckboxInputProps,
  AtlasFieldBaseProps,
  AtlasFieldInternal,
} from "atlas-ds";

export interface AtlasFieldCheckboxProps
  extends AtlasFieldBaseProps<HTMLInputElement, AtlasCheckboxInputProps> {}

/**
 * Une case à cocher seule.
 */
export function AtlasFieldCheckbox(props: AtlasFieldCheckboxProps) {
  return (
    <AtlasFieldInternal {...props} noLabel>
      <div className="atlas-fieldCheckbox">
        <AtlasCheckbox
          name={props.name}
          value={props.name}
          label={props.label}
          required={props.required}
          disabled={props.disabled}
          tooltipContent={props.tooltipContent}
          onChange={props.onChange}
          inputConstructor={props.inputConstructor}
          ariaInvalid={props.error ? true : undefined}
          ariaDescribedBy={props.error ? `${props.name}-error` : undefined}
        />
      </div>
    </AtlasFieldInternal>
  );
}
