import { AtlasIcon } from "atlas-ds";
import classNames from "classnames";
import { createRef, useState } from "react";

export interface CampusFiltersProps {
  /**
   * Les filtres
   */
  filters: {
    key: string;
    filter: React.ReactNode;
    full?: boolean;
  }[];
}

/**
 * Une interface réunissant des filtres de données
 */
export function CampusFilters(props: CampusFiltersProps) {
  const el = createRef<HTMLDetailsElement>(); // <details> element
  const summary = createRef<HTMLElement>(); // <summary> element
  const content = createRef<HTMLDivElement>(); // Content

  const [isOpen, setIsOpen] = useState(false);

  const onClick = (e: React.MouseEvent<HTMLElement, Event>) => {
    if (!el.current || !summary.current || !content.current) return;
    const $el = el.current;
    const $summary = summary.current;
    const $content = content.current;

    e.preventDefault();

    el.current.style.overflow = "hidden";

    // +2 is a magic number to get a better result
    const summaryHeight = $summary.offsetHeight + 2;

    if ($el.open) {
      animate($el, summaryHeight + 2, false);
    } else {
      $el.style.height = `${$el.offsetHeight}px`;
      setIsOpen(true);
      window.requestAnimationFrame(() =>
        animate($el, summaryHeight + $content.offsetHeight + 2, true)
      );
    }
  };

  const animate = (
    $el: HTMLDetailsElement,
    endHeight: number,
    open: boolean
  ) => {
    const animation = $el.animate(
      {
        height: [`${$el.offsetHeight}px`, `${endHeight}px`],
      },
      {
        duration: 200,
        easing: "ease-out",
        fill: "forwards",
      }
    );

    animation.onfinish = () => {
      setIsOpen(open);

      if (open) {
        $el.style.height = "";
        $el.style.overflow = "";
      }

      // We still need to cancel the animation because of fill: "forwards",
      // to release the height value
      setTimeout(() => animation.cancel());
    };
  };

  return (
    <details className="campus-filters" ref={el} open={isOpen}>
      <summary
        className="campus-filters__header"
        ref={summary}
        onClick={onClick}
      >
        <span>
          <AtlasIcon size="xs" name="filtres" />
          Filtrer les résultats
          <span className="campus-filters__icons">
            <AtlasIcon name="deplier" />
            <AtlasIcon name="replier" />
          </span>
        </span>
      </summary>

      <div className="campus-filters__inner" ref={content}>
        <form className="campus-filters__list">
          {props.filters.map((filter: any) => (
            <div
              key={filter.key}
              className={classNames("campus-filters__item", {
                "campus-filters__item--full": filter.full,
              })}
            >
              {filter.filter}
            </div>
          ))}
        </form>
      </div>
    </details>
  );
}
