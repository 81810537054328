export interface CampusIllustratedContentProps {
  /**
   * Le contenu
   */
  children: React.ReactNode;
  /**
   * L'image d'illustration
   */
  img: React.ReactElement<HTMLImageElement>;
  /**
   * Un logo à ajouter par dessus l'image principale
   */
  logo?: React.ReactElement<HTMLImageElement>;
}

/**
 * Un contenu accompagné d'une illustration
 */
export function CampusIllustratedContent(props: CampusIllustratedContentProps) {
  return (
    <div className="campus-illustratedContent">
      <div className="campus-illustratedContent__inner">
        <div>{props.children}</div>
        <div className="campus-illustratedContent__images">
          {props.img}
          {props.logo && (
            <div className="campus-illustratedContent__logo">{props.logo}</div>
          )}
        </div>
      </div>
    </div>
  );
}
