import {
  AtlasButton,
  AtlasButtonProps,
  AtlasLink,
  AtlasLinkProps,
  AtlasSelectProps,
  AtlasTooltip,
} from "atlas-ds";
import React from "react";

export interface AtlasFieldBaseProps<InputType = unknown, InputProps = {}> {
  /**
   * Le nom technique du champ
   */
  name: string;
  /**
   * Le label affiché
   */
  label: React.ReactNode;
  /**
   * Une instruction de remplissage supplémentaire
   */
  instructions?: string;
  /**
   * Le champ est-il requis ?
   */
  required?: boolean;
  /**
   * Cacher l'indicateur de champ requis ?
   * Utile pour un champ seul (recherche, par exemple).
   */
  hideRequiredHint?: boolean;
  /**
   * Une erreur de saisie présentée à l'utilisateur
   */
  error?: string | React.ReactNode;
  /**
   * Une tooltip d'aide à la saisie
   */
  tooltipContent?: React.ReactNode;
  /**
   * Un lien associé au champ
   */
  link?: React.ReactElement<AtlasLinkProps>;
  /**
   * Un boutton associé au champ
   */
  button?: React.ReactElement<AtlasButtonProps>;
  /**
   * Un sélecteur associé au champ, pour en modifier le comportement.
   * - pour un affichage correct, utiliser un AtlasSelect de type "mini"
   * - pour l'accessibilité, utiliser ariaLabel sur AtlasSelect (car il n'aur
   * pas de label visible)
   */
  select?: React.ReactElement<AtlasSelectProps>;
  /**
   * Le champ est-il désactivé ?
   * Inutilisé par ce composant mais utilisable par tous ceux qui en dérivent.
   */
  disabled?: boolean;
  /**
   * L'action à exécuter au changement de valeur
   * Inutilisé par ce composant mais utilisable par tous ceux qui en dérivent.
   */
  onChange?: React.ChangeEventHandler<InputType>;
  /**
   * Si un input natif ne convient pas, une fonction permettant d'en
   * construire un.
   * Inutilisé par ce composant mais utilisable par tous ceux qui en dérivent.
   */
  inputConstructor?: (props: InputProps) => JSX.Element;
}

interface AtlasFieldInternalProps extends AtlasFieldBaseProps<any, any> {
  /**
   * Le contenu du champ
   */
  children: React.ReactNode;
  /**
   * Cacher l'en-tête du champ.
   * Un input doit toujours être associé à un label.
   * Si ce booléen est utilisé c'est que le label est géré par un autre élément.
   */
  noLabel?: boolean;
  /**
   * L'élément est-il un groupe de champ ?
   * Si oui, le <label> sera une simple div retirée de l'arbre d'accessibilité.
   * L'élément appelant doit alors déclarer lui-même un <legend>, visuellement
   * caché.
   * https://adrianroselli.com/2022/07/use-legend-and-fieldset.html
   */
  isFieldset?: boolean;
}

/**
 * Composant destiné à la construction des différents types d'inputs.
 * Attention: ce composant n'est pas destiné à être utilisé directement !
 */
export function AtlasFieldInternal(props: AtlasFieldInternalProps) {
  const LabelTag = props.isFieldset ? "div" : "label";

  const link = props.link ? (
    <AtlasLink {...props.link.props} level={4} />
  ) : undefined;

  const button = props.button ? (
    <AtlasButton {...props.button.props} level={4} />
  ) : undefined;

  return (
    <div className="atlas-fieldInternal">
      {!props.noLabel && (
        <div className="atlas-fieldInternal__header">
          <LabelTag
            className="atlas-fieldInternal__label"
            htmlFor={props.isFieldset ? undefined : props.name}
            aria-hidden={props.isFieldset ? true : undefined}
          >
            {props.label}
            {props.required && !props.hideRequiredHint && (
              <span aria-hidden="true">&nbsp;* </span>
            )}
            {props.instructions && (
              <>
                &nbsp;<small>({props.instructions})</small>
              </>
            )}
          </LabelTag>
          {props.tooltipContent && (
            <div className="atlas-fieldInternal__tooltip">
              <AtlasTooltip
                title={`Plus d'informations sur le champ ${props.label}`}
              >
                {props.tooltipContent}
              </AtlasTooltip>
            </div>
          )}
          {props.select && (
            <div className="atlas-fieldInternal__select">{props.select}</div>
          )}
        </div>
      )}
      {props.children}
      {link}
      {button}
      {props.error && (
        <div className="atlas-fieldInternal__error" id={`${props.name}-error`}>
          {props.error}
        </div>
      )}
    </div>
  );
}
