import classNames from "classnames";
import { useState } from "react";
import { AtlasIconButton } from "../../../button";
import { AtlasIcon } from "../../../image";
import {
  AtlasFieldBaseProps,
  AtlasFieldInternal,
} from "../AtlasFieldInternal/AtlasFieldInternal";

export interface AtlasFieldPasswordInputProps {
  id: string;
  name: string;
  type: "text" | "password";
  required?: boolean;
  disabled?: boolean;
  autoComplete?: "new-password" | "current-password";
  "aria-invalid"?: boolean;
  "aria-describedby"?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

export interface AtlasFieldPasswordRule {
  /**
   * Le nom de la règle
   */
  label: string;
  /**
   * La règle est-elle respectée ?
   */
  pass: boolean;
}

export interface AtlasFieldPasswordProps
  extends AtlasFieldBaseProps<HTMLInputElement, AtlasFieldPasswordInputProps> {
  /**
   * La référence de remplissage automatique
   */
  autocomplete: "new-password" | "current-password";
  /**
   * Une liste de règles à respecter pour le mot de passe
   */
  rules?: AtlasFieldPasswordRule[];
}

/**
 * Un champ de saisie de mot de passe avec indicateur de contraintes
 */
export function AtlasFieldPassword(props: AtlasFieldPasswordProps) {
  const [reveal, setReveal] = useState(false);

  const inputProps: AtlasFieldPasswordInputProps = {
    id: props.name,
    name: props.name,
    type: reveal ? "text" : "password",
    autoComplete: props.autocomplete,
    required: props.required ? true : undefined,
    disabled: props.disabled ? true : undefined,
    "aria-describedby": props.rules
      ? `${props.name}-rules`
      : props.error
      ? `${props.name}-error`
      : undefined,
    "aria-invalid": props.error ? true : undefined,
    onChange: props.onChange,
  };

  const input = props.inputConstructor ? (
    props.inputConstructor(inputProps)
  ) : (
    <input {...inputProps} />
  );

  return (
    <AtlasFieldInternal
      {...props}
      error={props.rules ? undefined : props.error}
    >
      <div className="atlas-fieldPassword">
        {input}
        <div className="atlas-fieldPassword__reveal">
          <AtlasIconButton
            ariaLabel="Afficher le mot de passe"
            role="switch"
            ariaChecked={reveal}
            onClick={() => setReveal(!reveal)}
          >
            <AtlasIcon name={reveal ? "eye-closed" : "eye"} size="s" />
          </AtlasIconButton>
        </div>
      </div>
      {props.rules && (
        <ul className="atlas-fieldPassword__rules" id={`${props.name}-rules`}>
          {props.rules.map((rule) => (
            <li
              key={rule.label}
              className={classNames("atlas-fieldPassword__rule", {
                "atlas-fieldPassword__rule--pass": rule.pass,
              })}
            >
              {rule.label}
            </li>
          ))}
        </ul>
      )}
    </AtlasFieldInternal>
  );
}
