import { AtlasFieldBaseProps, AtlasFieldInternal } from "atlas-ds";
import {
  AtlasSelect,
  AtlasSelectInputProps,
  AtlasSelectOption,
} from "../../AtlasSelect/AtlasSelect";

export interface AtlasFieldSelectProps
  extends AtlasFieldBaseProps<HTMLSelectElement, AtlasSelectInputProps> {
  /**
   * Les options sélectionnables
   */
  options: AtlasSelectOption[];
  /**
   * La valeur de l'option sélectionnée
   */
  value?: string;
  /**
   * Ajouter une option initiale sans valeur
   */
  showEmptyOption?: boolean;
  /**
   * Le label de l'option initiale
   */
  emptyOptionLabel?: string;
}

/**
 * Un champ de sélection d'options
 */
export function AtlasFieldSelect(props: AtlasFieldSelectProps) {
  return (
    <AtlasFieldInternal {...props}>
      <AtlasSelect
        name={props.name}
        options={props.options}
        value={props.value}
        showEmptyOption={props.showEmptyOption}
        emptyOptionLabel={props.emptyOptionLabel}
        required={props.required}
        disabled={props.disabled}
        ariaInvalid={props.error ? true : undefined}
        ariaDescribedBy={props.error ? `${props.name}-error` : undefined}
        onChange={props.onChange}
        inputConstructor={props.inputConstructor}
      />
    </AtlasFieldInternal>
  );
}
