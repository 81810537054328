import { AtlasHeading, AtlasHeadingProps } from "atlas-ds";
import classNames from "classnames";

export interface CampusHeadingProps extends AtlasHeadingProps {
  /**
   * Un type de page (associé à un code couleur)
   */
  type?: "branche" | "domaine" | "thematique" | "module" | "session";
}

/**
 * Un titre principal. Il s'agit d'une extension du composant `Title` global.
 */
export function CampusHeading(props: CampusHeadingProps) {
  return (
    <div
      className={classNames("campus-title", {
        [`campus-title--${props.type}`]: props.type,
      })}
    >
      <AtlasHeading {...props} />
    </div>
  );
}

CampusHeading.extends = "AtlasHeading";