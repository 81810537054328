import { AtlasIcon } from "atlas-ds";
import classNames from "classnames";
import { MouseEventHandler } from "react";

export interface AtlasButtonProps {
  /**
   * Le label du bouton
   */
  children: string;
  /**
   * Identifiant du bouton
   */
  id?: string;
  /**
   * L'action exécutée au clic
   */
  onClick?: MouseEventHandler;
  /**
   * L'action exécutée lorsque le bouton de la souris est préssé
   */
  onMouseDown?: MouseEventHandler;
  /**
   * Le niveau d'importance visuelle du bouton (1 est le plus élevé)
   */
  level?: 1 | 2 | 3 | 4;
  /**
   * Type de bouton
   */
  type?: "button" | "submit";
  /**
   * L'icône à afficher au sein du bouton
   */
  icon?: string;
  /**
   * Désactiver le bouton. Le bouton est automatiquement désactier lorsqu'un
   * indicateur de chargement est actif.
   */
  disabled?: boolean;
  /**
   * Le bouton provoque t-il une action importante de supression ou
   * d'annulation ?
   */
  destructive?: boolean;
  /**
   * Si le bouton contient un indicateur de chargement, objet représentant son
   * état
   */
  spinner?: {
    spinning: boolean;
  };
  /**
   * Lier visuellement le bouton à l'identité Atlas
   */
  atlasBrand?: boolean;
  /**
   * La description accessible du bouton. Attention, ce texte remplacera le
   * contenu du bouton pour les technologies d'assistance. À n'utiliser que s'il
   * est impossible de rendre le label du lien suffisamment explicite.
   */
  ariaLabel?: string;
}

/**
 * Un bouton produisant une action au clic
 */
export function AtlasButton(props: AtlasButtonProps) {
  let icon;

  if (props.icon) {
    // Use the given icon unless the spinner must be shown
    icon = props.spinner?.spinning ? "spinner" : props.icon;
  } else if (props.spinner) {
    // Prepare the spinner icon
    icon = "spinner";
  }

  // Destructive and atlasBrand buttons are forced to level 1
  const level = props.destructive || props.atlasBrand ? 1 : props.level || 1;

  return (
    <button
      className={classNames("atlas-button", `atlas-button--level${level}`, {
        "atlas-button--destructive": props.destructive,
        "atlas-button--hasIcon": props.icon,
        "atlas-button--spinner": props.spinner,
        "atlas-button--spinning": props.spinner?.spinning,
        "atlas-button--atlasBrand": props.atlasBrand,
      })}
      id={props.id}
      type={props.type || "button"}
      onClick={props.onClick}
      onMouseDown={props.onMouseDown}
      disabled={props.spinner?.spinning || props.disabled}
      aria-label={props.ariaLabel}
    >
      {icon && <AtlasIcon name={icon} size={level >= 3 ? "s" : undefined} />}
      {props.children}
    </button>
  );
}
