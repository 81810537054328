import { AtlasFieldBaseProps, AtlasFieldInternal } from "atlas-ds";

export interface AtlasFieldRadioGroupInputProps {
  type: "radio";
  id: string;
  value: string;
  name: string;
  checked?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

export interface AtlasFieldRadioGroupOption {
  /**
   * Le label affiché
   */
  label: string;
  /**
   * La valeur de l'option
   */
  value: string;
  /**
   * L'option est-elle sélectionnée ?
   */
  checked?: boolean;
}

export interface AtlasFieldRadioGroupProps
  extends AtlasFieldBaseProps<
    HTMLInputElement,
    AtlasFieldRadioGroupInputProps
  > {
  /**
   * Les options sélectionnables
   */
  options: AtlasFieldRadioGroupOption[];
}

/**
 * Une série de boutons radios
 */
export function AtlasFieldRadioGroup(props: AtlasFieldRadioGroupProps) {
  return (
    <fieldset
      id={props.name}
      className="atlas-fieldRadioGroup"
      role="radiogroup"
      aria-required={props.required}
      aria-invalid={props.error ? "true" : undefined}
      aria-describedby={props.error ? `${props.name}-error` : undefined}
      tabIndex={-1}
      disabled={props.disabled}
    >
      <legend>
        {props.label} {props.instructions}
      </legend>
      <AtlasFieldInternal isFieldset {...props}>
        <div className="atlas-fieldRadioGroup__options">
          {props.options.map((option) => {
            const inputProps: AtlasFieldRadioGroupInputProps = {
              type: "radio",
              id: `${props.name}-${option.value}`,
              value: option.value,
              name: props.name,
              checked: option.checked,
              onChange: props.onChange,
            };

            const input = props.inputConstructor ? (
              props.inputConstructor(inputProps)
            ) : (
              <input {...inputProps} />
            );

            return (
              <div
                className="atlas-fieldRadioGroup__option"
                key={inputProps.id}
              >
                {input}
                <label htmlFor={inputProps.id}>
                  <span
                    className="atlas-fieldRadioGroup__symbol"
                    aria-hidden="true"
                  ></span>
                  {option.label}
                </label>
              </div>
            );
          })}
        </div>
      </AtlasFieldInternal>
    </fieldset>
  );
}
