import { AtlasFieldBaseProps, AtlasFieldInternal } from "../AtlasFieldInternal";

export interface AtlasFieldDurationInputProps {
  id: string;
  name: string;
  inputMode: "numeric";
  title: string;
  "aria-invalid"?: "true" | "false";
  "aria-describedby"?: string;
  disabled?: boolean;
  required?: boolean;
}

export interface AtlasFieldDurationProps
  extends AtlasFieldBaseProps<HTMLInputElement, AtlasFieldDurationInputProps> {
  /**
   * Le nom technique du champ heures
   * (note : le "name" original, même s'il n'est associé à aucun champ,
   * reste utile pour la structure du composant)
   */
  nameHours: string;
  /**
   * Le nom technique du champ minutes
   * (note : le "name" original, même s'il n'est associé à aucun champ,
   * reste utile pour la structure du composant)
   */
  nameMinutes: string;
}

/**
 * Un double input pour insérer des heures et des minutes
 */
export function AtlasFieldDuration(props: AtlasFieldDurationProps) {
  const hoursInputProps: AtlasFieldDurationInputProps = {
    id: props.nameHours,
    name: props.nameHours,
    title: `${props.label} : heures`,
    "aria-invalid": props.error ? "true" : "false",
    "aria-describedby": props.error ? `${props.name}-error` : undefined,
    inputMode: "numeric",
    disabled: props.disabled,
    required: props.required,
  };

  const minutesInputProps: AtlasFieldDurationInputProps = {
    id: props.nameMinutes,
    name: props.nameMinutes,
    title: `${props.label} : minutes`,
    "aria-invalid": props.error ? "true" : "false",
    "aria-describedby": props.error ? `${props.name}-error` : undefined,
    inputMode: "numeric",
    disabled: props.disabled,
    required: props.required,
  };

  const hoursInput = props.inputConstructor ? (
    props.inputConstructor(hoursInputProps)
  ) : (
    <input {...hoursInputProps} />
  );

  const minutesInput = props.inputConstructor ? (
    props.inputConstructor(minutesInputProps)
  ) : (
    <input {...minutesInputProps} />
  );

  return (
    <fieldset id={props.name} className="atlas-fieldDuration">
      <legend>
        {props.label} {props.instructions}
      </legend>
      <AtlasFieldInternal {...props} isFieldset>
        <div className="atlas-fieldDuration__inputs">
          <div className="atlas-fieldDuration__input">{hoursInput}h</div>
          <div className="atlas-fieldDuration__input">{minutesInput}min</div>
        </div>
      </AtlasFieldInternal>
    </fieldset>
  );
}
