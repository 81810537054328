import { AtlasIcon, AtlasTooltip } from "atlas-ds";

export interface CampusCardTagProps {
  /**
   * Le label
   */
  children: string;
  /**
   * Le contenu de la tooltip
   */
  tooltipContent?: React.ReactNode;
}

/**
 * Un tag destiné à enrichir une card
 */
export function CampusCardTag(props: CampusCardTagProps) {
  const opener = (
    <div className="campus-cardTag__inner">
      {props.children}

      {props.tooltipContent && <AtlasIcon name="info" size="xs" />}
    </div>
  );

  return (
    <div className="campus-cardTag">
      {props.tooltipContent ? (
        <AtlasTooltip opener={opener}>{props.tooltipContent}</AtlasTooltip>
      ) : (
        opener
      )}
    </div>
  );
}
