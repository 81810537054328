import { AtlasIcon } from 'atlas-ds';
import { useEffect, useState } from "react";

export interface CampusShareProps {
  /**
   * Le texte du contenu à partager
   */
  text: string;
}

/**
 * Un bouton de partage de la page courante.
 * Il apparaît uniquement si le navigateur supporte l'api `share`.
 */
export function CampusShare(props: CampusShareProps) {
  const [hasShareCapacity, setHasShareCapacity] = useState(false);

  // Use useEffect for client-side only execution
  useEffect(() => {
    setHasShareCapacity(!!navigator.share);
  }, []);

  // Trim the given text if it's too long
  function trim(text: string, length: number) {
    if (text.length > length) {
      return text.slice(0, length) + "…";
    }

    return text;
  }

  function share() {
    navigator.share({
      url: window.location.toString(),
      title: document.title,
      text: trim(props.text.replace(/(<([^>]+)>)/gi, ""), 160),
    });
  }

  if (!hasShareCapacity) {
    return <></>;
  }

  return (
    <button className="a-share" onClick={share}>
      <AtlasIcon size="s" name="partager" />
      Partager
    </button>
  );
}
