import { AtlasIcon } from 'atlas-ds';
import classNames from "classnames";
import { ForwardedRef, MouseEventHandler, forwardRef } from "react";

export interface AtlasUserButtonProps {
  /**
   * Le nom de l'utilisateur, s'il est connecté
   */
  userName?: string;
  /**
   * L'action à éxécuter au clic sur le bouton
   */
  onClick?: MouseEventHandler<HTMLButtonElement>;
  /**
   * L'élément associé au bouton (s'il existe) est-il ouvert ?
   */
  open?: boolean;
}

/**
 * Le bouton de connexion ou d'ouverture du menu utilisateur
 */
export const AtlasUserButton = forwardRef(function AtlasUserButton(
  props: AtlasUserButtonProps,
  forwardedRef: ForwardedRef<any>
) {
  return (
    <button
      className={classNames("atlas-userButton", {
        "atlas-userButton--open": props.open,
      })}
      onClick={props.onClick}
      ref={forwardedRef}
      type="button"
      aria-expanded={props.open}
    >
      {props.userName ? (
        <span>
          <span className="atlas-userButton__name">{props.userName}</span>
          Mes services myAtlas
          <AtlasIcon size="xs" name="arrow-down" />
        </span>
      ) : (
        "Me connecter à myAtlas"
      )}
    </button>
  );
});
