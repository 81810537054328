import classNames from "classnames";

export interface AtlasIconProps {
  /**
   * Le nom de l'icône dans la banque d'icônes
   */
  name: string;
  /**
   * La taille de l'icône (m par défaut)
   */
  size?: "l" | "m" | "s" | "xs";
  /**
   * Le nom accessible de l'icône. Cet attribut est nécessaire uniquement dans
   * le cas où l'icône n'est pas accompagnée par un texte visible et accessible.
   */
  title?: string;
}

/**
 * Une icône
 */
export function AtlasIcon(props: AtlasIconProps) {
  const className = classNames("atlas-icon", {
    [`atlas-icon--${props.size}`]: props.size,
  });

  return (
    <svg className={className} aria-hidden={props.title ? undefined : "true"}>
      {props.title && <title>{props.title}</title>}
      <use href={`#icon-${props.name}`}></use>
    </svg>
  );
}
