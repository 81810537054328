import { AtlasIcon, AtlasIconButton } from "atlas-ds";
import { MouseEventHandler } from "react";

export interface AtlasTagsProps {
  /**
   * La liste des tags
   */
  children: React.ReactElement<AtlasTagsItemProps>[];
}

/**
 * Une liste de tags
 */
export function AtlasTags(props: AtlasTagsProps) {
  return <div className="atlas-tags">{props.children}</div>;
}

export interface AtlasTagsItemProps {
  /**
   * La valeur du tag
   */
  children: string;
  /**
   * Un label à afficher avant la valeur
   */
  label?: string;
  /**
   * Une action de suppression et sa description
   */
  delete?: {
    onDelete: MouseEventHandler<HTMLButtonElement>;
    ariaLabel: string;
  };
}

AtlasTags.Item = (props: AtlasTagsItemProps) => {
  return (
    <span className="atlas-tags__item">
      {props.label && (
        <span className="atlas-tags__label">{props.label} : </span>
      )}

      <div className="atlas-tags__valueAndDelete">
        <span className="atlas-tags__value">{props.children}</span>

        {props.delete && (
          <div className="atlas-tags__delete">
            <AtlasIconButton
              ariaLabel={props.delete.ariaLabel}
              onClick={props.delete.onDelete}
            >
              <AtlasIcon name="close" size="xs" />
            </AtlasIconButton>
          </div>
        )}
      </div>
    </span>
  );
};
