import { AtlasLoading } from "atlas-ds"
import classNames from "classnames"

export interface AtlasValuesProps {
  /**
   * Les valeurs et lignes de valeurs
   **/
  children: React.ReactNode;
  /**
   * Le nom de l'entité représentée
   */
  label?: string;
}

/**
 * Un groupe de valeurs
 */
export function AtlasValues(props: AtlasValuesProps) {
  return (
    <div className="atlas-values">
      {"label" in props && (
        <p className="atlas-values__label">
          <AtlasLoading.Loader />
          <span>{props.label}</span>
        </p>
      )}

      <div className="atlas-values__inner">{props.children}</div>
    </div>
  );
}

export interface AtlasValuesGridProps {
  /**
   * Les valeurs
   */
  children: React.ReactNode;
  /**
   * Un label pour cette sous-partie
   */
  label?: string;
  /**
   * Passer de 2 à 4 valeurs par ligne
   */
  condensed?: boolean;
}

/**
 * Une série de valeurs, présentés par défaut sous la forme d'une grille à deux
 * colonnes. L'utilisation de ce conteneur n'est pas obligatoire au sein d'un
 * groupe de valeurs. S'il n'est pas utilisé, les valeurs seront simplement les
 * unes à la suite des autres.
 */
AtlasValues.Grid = (props: AtlasValuesGridProps) => {
  return (
    <div
      className={classNames("atlas-values__grid", {
        "atlas-values__grid--condensed": props.condensed,
      })}
    >
      {props.label && <p className="atlas-values__gridLabel">{props.label}</p>}
      <div className="atlas-values__gridContent">{props.children}</div>
    </div>
  );
};
