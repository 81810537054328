import { AtlasTooltipProps, CampusHeading, CampusShare } from "atlas-ds";
import classNames from "classnames";

export interface CampusFeaturesFeature {
  title: string;
  content: React.ReactNode;
  iconName?: string;
}

export interface CampusFeaturesProps {
  /**
   * Les caractéristiques principales, qui auront plus de place (par exemple un
   * long bloc de texte)
   */
  mainColumnFeatures: CampusFeaturesFeature[];
  /**
   * Les caractéristiques "courtes", présentées sur le côté
   */
  sideColumnFeatures?: CampusFeaturesFeature[];
  /**
   * Le texte utilisé pour la fonctionnalité de partage du navigateur
   */
  shareText?: string;
  /**
   * Ajouter une couleur de fond ?
   */
  filled?: boolean;
}

/**
 * Une présentation de caractéristiques
 */
export function CampusFeatures(props: CampusFeaturesProps) {
  const getCellContent = (feature: CampusFeaturesFeature) => (
    <>
      <CampusHeading tag="h2" size="m" iconName={feature.iconName}>
        {feature.title}
      </CampusHeading>
      {feature.content}
    </>
  );

  return (
    <section
      className={classNames("campus-features", {
        "campus-features--filled": props.filled,
      })}
    >
      <div className="campus-features__inner">
        {props.sideColumnFeatures && (
          <div className="campus-features__column">
            {props.sideColumnFeatures.map((feature) => (
              <div className="campus-features__cell" key={feature.title}>
                {getCellContent(feature)}
              </div>
            ))}
          </div>
        )}
        <div className="campus-features__column">
          {props.mainColumnFeatures.map((feature, index) => (
            <div className="campus-features__cell" key={feature.title}>
              {getCellContent(feature)}
              {index === props.mainColumnFeatures.length - 1 &&
                props.shareText && (
                  <div className="campus-features__share">
                    <CampusShare text={props.shareText} />
                  </div>
                )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export interface CampusFeaturesSubtitleProps {
  /**
   * Le titre
   */
  children: string;
  /**
   * Une tooltip d'informations
   */
  tooltip?: React.ReactElement<AtlasTooltipProps>;
}

/**
 * Un sous-titre pour une caractéristique
 */
CampusFeatures.Subtitle = (props: CampusFeaturesSubtitleProps) => {
  return (
    <div className="campus-features__subtitle">
      {props.children}

      {props.tooltip && (
        <div className="campus-features__tooltip">{props.tooltip}</div>
      )}
    </div>
  );
};

export interface CampusFeaturesContainerProps {
  /**
   * Le contenu
   */
  children: React.ReactNode;
}

/**
 * Un conteneur avec couleur de fond pour une sous-partie
 */
CampusFeatures.Container = (props: CampusFeaturesContainerProps) => {
  return <div className="campus-features__container">{props.children}</div>;
};
