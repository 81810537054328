import classNames from "classnames";

export interface CampusContainerProps {
  /**
   * Le contenu
   */
  children: React.ReactNode;
  /**
   * Colorer le conteneur
   */
  colored?: boolean;
  /**
   * Limiter la largeur du conteneur
   */
  limitedWidth?: boolean;
}

/**
 * Un conteneur générique
 */
export function CampusContainer(props: CampusContainerProps) {
  return (
    <div
      className={classNames("campus-container", {
        "campus-container--colored": props.colored,
        "campus-container--limitedWidth": props.limitedWidth,
      })}
    >
      {props.children}
    </div>
  );
}
