export interface AtlasSpacerProps {
  /**
   * La taille de l'espace
   */
  size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
}

/**
 * Un bloc vide permettant de créer un espace vierge
 */
export function AtlasSpacer(props: AtlasSpacerProps) {
  return (
    <div
      className="atlas-spacer"
      style={{ height: `var(--atlas-space-${props.size || 'm'})` }}
    ></div>
  );
}
