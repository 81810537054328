import classNames from "classnames";

interface AtlasFlexProps {
  /**
   * Les enfants du conteneur
   */
  children: React.ReactNode;
  /**
   * Passer le conteneur en colonne (ligne par défaut)
   */
  column?: boolean;
  /**
   * L'espace à insérer entre chaque enfant
   */
  gap?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
  /**
   * L'espace à insérer entre chaque ligne créée
   */
  rowGap?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
  /**
   * L'espace à insérer entre chaque colonne créée
   */
  columnGap?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
  /**
   * Répartition des éléments sur l'axe principal (horizontalement dans le cas
   * d'une ligne, verticalement dans le cas d'une colonne). Par défault, les
   * éléments sont au début du conteneur
   */
  justifyContent?: "start" | "center" | "end" | "space-between";
  /**
   * Centrage des éléments sur l'axe secondaire (horizontalement dans le cas
   * d'une colonne, verticalement dans le cas d'une ligne). Par défault, les
   * éléments sont étendus.
   */
  alignItems?: "start" | "center" | "end" | "baseline";
  /**
   * Permettre le retour à la ligne en cas d'espace insuffisant
   */
  wrap?: boolean;
  /**
   * Agrandir automatiquement les éléments en ligne
   */
  grow?: boolean;
  /**
   * Imposer un nombre minimal d'emplacements pour les enfants.
   * Exemple : si ce paramètre vaut `2` mais qu'il n'y a qu'un enfant, un autre
   * enfant vide sera ajouté pour combler le vide (et empêcher le premier
   * enfant de grandir au delà de la moitié du conteneur).
   * `grow` doit être à `true` pour observer un effet.
   */
  minimumSlots?: number;
}

/**
 * Un élément de présentation flexible. Permet d'espacer horizontalement ou
 * verticalement les éléments enfants.
 */
export const AtlasFlex = (props: AtlasFlexProps) => {
  let childrenToAdd;

  if (props.minimumSlots) {
    const childrenLength = Array.isArray(props.children)
      ? props.children.filter(Boolean).length
      : 1;
    const missingChildren = Math.max(0, props.minimumSlots - childrenLength);
    childrenToAdd = Array(missingChildren)
      .fill(true)
      .map((_, index) => <div key={`placeholder-${index}`}></div>);
  }

  return (
    <div
      className={classNames("atlas-flex", {
        "atlas-flex--grow": props.grow,
        "atlas-flex--wrap": props.wrap,
      })}
      style={{
        ...(props.gap ? { gap: `var(--atlas-space-${props.gap})` } : {}),
        ...(props.rowGap
          ? { rowGap: `var(--atlas-space-${props.rowGap})` }
          : {}),
        ...(props.columnGap
          ? { columnGap: `var(--atlas-space-${props.columnGap})` }
          : {}),
        ...(props.column ? { flexDirection: "column" } : {}),
        ...(props.justifyContent
          ? { justifyContent: props.justifyContent }
          : {}),
        ...(props.alignItems ? { alignItems: props.alignItems } : {}),
      }}
    >
      {props.children}
      {childrenToAdd}
    </div>
  );
};

interface AtlasFlexGrowProps {
  /**
   * L'élément qui doit grandir
   */
  children: React.ReactNode;
}

/**
 * Un élément qui, placé au sein d'un conteneur `AtlasFlex`, grandira pour
 * remplir le maximum d'espace disponible. Si tous les éléments doivent grandir,
 * utilisez la propriété `grow` de l'élément `AtlasFlex`.
 */
AtlasFlex.Grow = (props: AtlasFlexGrowProps) => (
  <div className="atlas-flex__grow">{props.children}</div>
);
