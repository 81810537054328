import {
  AtlasIcon,
  AtlasIconButton,
  AtlasInfoType,
  AtlasLinkProps,
} from "atlas-ds";
import classNames from "classnames";
import { MouseEventHandler, useEffect, useRef, useState } from "react";

export interface AtlasToastsProps {
  /**
   * Les toasts individuels
   */
  children: React.ReactElement<AtlasToastsItemProps>[];
}

/**
 * Les toasts à afficher sur l'application.
 * Tous doivent être inclus dans ce composant global.
 */
export function AtlasToasts(props: AtlasToastsProps) {
  return (
    <div
      className="atlas-toasts"
      role="region"
      aria-label="Activité de l'application"
    >
      <div className="atlas-toasts__inner">{props.children}</div>
    </div>
  );
}

export type AtlasToastsItemType = AtlasInfoType;

export interface AtlasToastsItemProps {
  /**
   * Le titre de l'information
   */
  label: string;
  /**
   * Le contenu de l'information
   */
  children: string;
  /**
   * L'action à éxécuter à la fermeture du toast
   */
  onClose: MouseEventHandler<HTMLButtonElement>;
  /**
   * En millisecondes, une durée après laquelle fermer automatiquement le toast.
   * La fermeture sera automatiquement suspendue si la zone des toasts est
   * survolée ou obtient le focus, jusqu'à ce que ne soit plus le cas.
   */
  closeAfterDelay?: number;
  /**
   * Une icône associée à l'information (sinon, une icône par défaut sera
   * utilisée).
   * Note : les icônes ne sont pas supportées sur les toasts larges.
   */
  icon?: string;
  /**
   * Un lien lié à l'information
   */
  link?: React.ReactElement<AtlasLinkProps>;
  /**
   * Faut-il fermer le toast lorsque le lien est cliqué ?
   */
  closeOnLinkClick?: boolean;
  /**
   * Le type d'information
   */
  type?: AtlasToastsItemType;
  /**
   * Version plus grande du toast
   */
  large?: boolean;
}

/**
 * Un toast
 */
AtlasToasts.Item = (props: AtlasToastsItemProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const getWrapper = () => ref.current?.closest(".atlas-toasts");
  let timeout: any;
  const [isClosing, setIsClosing] = useState(false);

  const link = props.link
    ? {
        ...props.link,
        props: {
          level: 3,
          icon: "arrow-right",
          ...props.link.props,
        },
      }
    : undefined;

  const onClose = () => {
    setIsClosing(true);
    setTimeout(props.onClose, 250);

    // Clear listeners
    const wrapper = getWrapper();

    wrapper?.removeEventListener("mouseenter", clearClosing);
    wrapper?.removeEventListener("focusin", clearClosing);
    wrapper?.removeEventListener("mouseleave", closeAfterDelay);
    wrapper?.removeEventListener("focusout", closeAfterDelay);
  };

  const closeAfterDelay = () => {
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(onClose, props.closeAfterDelay);
  };

  const clearClosing = () => clearTimeout(timeout);

  useEffect(() => {
    if (props.closeAfterDelay) {
      closeAfterDelay();

      return () => clearTimeout(timeout);
    }
  }, []);

  useEffect(() => {
    if (props.closeAfterDelay && ref.current) {
      const wrapper = getWrapper();

      // Cancel programmed closing when the toast section is focused
      wrapper?.addEventListener("mouseenter", clearClosing);
      wrapper?.addEventListener("focusin", clearClosing);

      // Re-program the closing when the toast section is not focused anymore
      wrapper?.addEventListener("mouseleave", closeAfterDelay);
      wrapper?.addEventListener("focusout", closeAfterDelay);
    }
  }, []);

  return (
    <div
      ref={ref}
      className={classNames(
        "atlas-toasts__item",
        `u-atlas-color-${props.type ?? "info"}`,
        {
          "atlas-toasts__item--large": props.large,
          "atlas-toasts__item--isClosing": isClosing,
        }
      )}
      role={
        props.type && ["warning", "error"].includes(props.type)
          ? "alert"
          : "status"
      }
    >
      <div className="atlas-toasts__itemInner">
        <AtlasIconButton ariaLabel="Fermer" onClick={onClose}>
          <AtlasIcon name="close" size="s" />
        </AtlasIconButton>
        <div className="atlas-toasts__itemHeader">
          {!props.large && (
            <AtlasIcon name={props.icon ?? props.type ?? "info"} size="xs" />
          )}
          {props.label}
        </div>
        <p className="atlas-toasts__itemContent">{props.children}</p>
        <div onClick={props.closeOnLinkClick ? onClose : undefined}>{link}</div>
      </div>
    </div>
  );
};
