import {
  AtlasIcon,
  AtlasLinkConstructor,
  AtlasLoading,
  defaultLinkConstructor,
} from "atlas-ds";

export interface CampusCardListProps {
  /**
   * Les éléments
   */
  children: React.ReactNode[];
  /**
   * Un lien pour voir plus d'éléments
   */
  moreCta?: {
    label: string;
    href: string;
    linkConstructor?: AtlasLinkConstructor;
  };
  /**
   * S'il s'agit d'un flux infini, le nombre total d'éléments.
   * Usage interne uniquement !
   */
  internalFeedTotalCount?: number;
}

/**
 * Une liste de d'éléments sous forme de cartes
 */
export function CampusCardList(props: CampusCardListProps) {
  const linkConstructor =
    props.moreCta?.linkConstructor || defaultLinkConstructor;

  return (
    <div
      className="campus-cardList"
      role={props.internalFeedTotalCount ? "feed" : undefined}
    >
      {props.children.map((child: React.ReactNode, index) => (
        <article
          aria-posinset={props.internalFeedTotalCount ? index + 1 : undefined}
          aria-setsize={props.internalFeedTotalCount}
          key={`card-${index}`}
        >
          {child}
        </article>
      ))}

      <AtlasLoading.Loader />
      <AtlasLoading.Loader />
      <AtlasLoading.Loader />
      <AtlasLoading.Loader />
      <AtlasLoading.Loader />
      <AtlasLoading.Loader />

      {props.moreCta && (
        <div className="campus-cardList__more">
          {linkConstructor(
            { href: props.moreCta.href },
            <>
              <div className="campus-cardList__moreLabel">
                {props.moreCta.label}
              </div>

              <div className="campus-cardList__moreIcon">
                <AtlasIcon name="arrow-right" />
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}
