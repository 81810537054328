import { AtlasIcon, AtlasLinkConstructor, defaultLinkConstructor } from "atlas-ds";
import { CampusCardTagProps } from "../CampusCardTag/CampusCardTag";

export interface CampusModuleCardProps {
  /**
   * Le nom du module
   */
  label: string;
  /**
   * Les informations supplémentaires sur le module
   */
  infos: {
    value: string | number;
    label: string;
  }[];
  /**
   * Le lien vers le module
   */
  href: string;
  /**
   * Si un lien classique ne suffit pas, un constructeur de lien
   */
  linkConstructor?: AtlasLinkConstructor;
  /**
   * Un tag à ajouter au module
   */
  tag?: React.ReactElement<CampusCardTagProps>;
}

/**
 * Un module de formation
 */
export function CampusModuleCard(props: CampusModuleCardProps) {
  const linkConstructor = props.linkConstructor ?? defaultLinkConstructor;

  return (
    <div className="campus-moduleCard">
      {linkConstructor(
        {
          href: props.href,
          "aria-label": props.label,
          className: "campus-moduleCard__link",
        },
        <>
          <header className="campus-moduleCard__header">
            <AtlasIcon name="module" />

            <h3 className="campus-moduleCard__label">{props.label}</h3>
          </header>

          <div className="campus-moduleCard__infos">
            {props.infos.map((info) => (
              <p key={info.label}>
                <strong>{info.value}</strong> {info.label}
              </p>
            ))}
          </div>
        </>
      )}
      {props.tag && <div className="campus-moduleCard__tag">{props.tag}</div>}
    </div>
  );
}
